<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <MainForm :form="form" :purpose="purpose" :hideUploadParentalConsent="true">
            <template v-slot:main>
              <b-form-group id="input-group-interest">
                <label for="input-interest">Kepeminatan:</label>
                <b-form-input
                  id="input-interest"
                  v-model="form.interest"
                  placeholder="Kepeminatan"
                ></b-form-input>
                <small class="text-danger">{{ error.interest }}</small>
              </b-form-group>

              <b-form-group id="input-group-notes">
                <label for="input-notes">Keterangan:</label>
                <b-form-textarea
                  id="input-notes"
                  v-model="form.notes"
                  placeholder="Tuliskan secara detail jenis sampel dan jumlah sampel"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{ error.notes }}</small>
              </b-form-group>
            </template>
          </MainForm>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/sem-registration')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>

    <ModalSubject @chooseData="chooseDataSubject" />
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/lab/MainFormLetter.vue";
import ModalSubject from "@/view/components/general/ModalSubject.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    currentPhoto: String,
  },
  components: {
    MainForm,
    ModalSubject,
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        semester_id: "",
        parental_consent: "",
        room_id: "",
        date: "",
        start_time: "",
        end_time: "",
      },
      current_photo: "/img/default/blank.jpg",
      default_photo: "/img/default/blank.jpg",
      semesters: [],
      display: {
        subject_name: "",
      },
      teachers: [],
      rooms: [],
      menuDate1: false,
      menustart: false,
      menuend: false,
    };
  },
  methods: {
    chooseDataSubject(value) {
      this.$bvModal.hide("modal-subject");
      this.form.subject_id = value.id;
      this.display.subject_name = value.name;
    },
    async getTeacherOption() {
      let response = await module.setTreeSelect(
        "api/users?page_size=40&role_id=1"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers = response.data;
        this.teachers.unshift({
          label: "Pilih Dosen Pengampu",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getRoomOption() {
      let response = await module.setTreeSelectRoom2(
        "api/rooms?page_size=40&name=Lab"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.rooms = response.data;
        this.rooms.unshift({
          label: "Pilih Laboratorium",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getSemesterActive() {
      let response = await module.get("api/semesters/now");
      // If Data Not Found
      if (response != null) {
        this.form.semester_id = response.id;
      }
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.sign_image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo = "";
    },
    validationCheck() {
      let status = true;
      if (this.purpose == "add") {
        if (
          this.form.hasOwnProperty("parental_consent_file") == false ||
          this.form.parental_consent_file == ""
        ) {
          this.error.parental_consent_file =
            "Surat izin orang tua belum dipilih";
          status = false;

          this.$root.$emit("validationImageTrue");
          console.log("send emit");
        } else {
          this.error.parental_consent_file = "";
          this.$root.$emit("validationImageFalse");
        }
      }

      return status;
    },
    async formOnsubmit() {
    //   if (this.validationCheck() == false) {
    //     Swal.fire({
    //       title: "Gagal",
    //       text: "Harap periksa kembali form",
    //       icon: "error",
    //       toast: true,
    //       position: "top-end",
    //       showConfirmButton: false,
    //       timer: 3000,
    //       timerProgressBar: true,
    //     });
    //     throw new Error("Validation Error");
    //   }

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/sem-registration");
      }
    },
  },
  mounted() {
    this.getSemesterOption();
    this.getSemesterActive();
    this.getTeacherOption();
    this.getRoomOption();
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;
        this.current_photo = this.form.sign;
        this.default_photo = this.form.sign;
        this.display.subject_name = this.form.subject_name;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
